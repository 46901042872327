<template>

  <div>

    <list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role="role.options"
      @refetch-data="refetchData"
      @emitAddSuccess="emitAddSuccess"
      @emitAddError="emitAddError"
    />

    <!-- Filters -->
    <list-filters
      :role-filter.sync="roleFilter"
      :roleOptions="role.options"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
                v-if="$can('create', 'pengguna')"
              >
                <span class="text-nowrap">Tambah User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :to="{ name: 'pages-profile-detail', params: { id: data.item.id } }"
                :text="avatarText(data.item.nama)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                title="Lihat Profil"
              />
            </template>
              {{ data.item.name }}
              <div
                v-if="data.item.email_verified_at"
              >
                <feather-icon icon="CheckCircleIcon" />
              </div>
              <div
                v-else
              >
                <feather-icon icon="XCircleIcon" />
              </div>
          </b-media>
        </template>

        <template #cell(email)="data">
          <b-media vertical-align="center">
            {{ data.item.email }}
          </b-media>
        </template>

           <template #cell(role)="data">
          <b-media vertical-align="center">
            {{ data.item.v1 }}
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template> -->
          <b-button
            class="mr-1 mb-1 btn-icon btn btn-relief-primary"
            variant="success"
            v-if="$can('all', 'manage') && data.item.email_verified_at !== null"
            @click="activation('non-aktif', data.item.id)"
            title="User Aktif"
          >
            <feather-icon icon="CheckCircleIcon" />
          </b-button>
          <b-button
            class="mr-1 mb-1 btn-icon btn btn-relief-primary"
            variant="danger"
            v-if="$can('all', 'manage') && data.item.email_verified_at === null"
            @click="activation('aktif', data.item.id)"
            title="User Non Aktif"
          >
            <feather-icon icon="XCircleIcon" />
          </b-button>
          <b-button
            class="mr-1 mb-1 btn-icon btn btn-relief-primary"
            variant="info"
            v-if="$can('loginas', 'user')"
            @click="loginAs(data.item.id, data.item.email)"
            title="Login Sebagai"
          >
            <feather-icon icon="LockIcon" />
            <!-- <span class="align-middle ml-50">Delete</span> //-->
          </b-button>

          <b-button
            class="mr-1 mb-1 btn-icon btn btn-relief-primary"
            variant="info"
            @click="verifyEmail(data.item.id, data.item.email)"
            title="Kirim Verifikasi"
          >
            <feather-icon icon="MailIcon" />
            <!-- <span class="align-middle ml-50">Edit</span> //-->
          </b-button>

          <b-button
            class="mr-1 mb-1 btn-icon btn btn-relief-primary"
            variant="success"
            :to="{ name: 'pages-account-edit', params: { id: data.item.id } }"
            title="Ubah Data"
            v-if="$can('update', 'pengguna')"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            class="mb-1 btn-icon btn btn-relief-primary"
            variant="danger"
            v-if="$can('delete', 'pengguna')"
            @click="deleteData(data.item.id)"
            title="Delete"
          >
            <feather-icon icon="TrashIcon" />
            <!-- <span class="align-middle ml-50">Delete</span> //-->
          </b-button>
          <!-- </b-dropdown> -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  getRole,
  activationUser,
} from '@/connection-api/master'
import {
  softDeleteUser,
} from '@/api/user'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/user/storeModule'
import useList from '@/connection-api/user/list'
import {
  Authlogin,
  setToken,
  setRefreshToken,
  VerifyEmail,
} from '@/connection-api/authentication'
import listAddNew from './ListAddNew.vue'
import listFilters from './ListFilters.vue'

export default {
  components: {
    listFilters,
    listAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      kanwilOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Kanwil --' },
        ],
      },
      uptOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih UPT --' },
        ],
      },
      role: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Role --' },
        ],
      },
    }
  },
  async mounted() {
    // // const responseKanwilOptions = await getKanwil()
    // // this.mappingSelectOption(responseKanwilOptions.data, this.kanwilOptions.options)
    // this.reloadUPTOptions()

    const responserole = await getRole()
    this.mappingSelectOptionRole(responserole.data, this.role.options)
  },
  methods: {
    async deleteData(id) {
      const response = await softDeleteUser({ user_id: id })
      if (response.data.success === true) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Sukses',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'User Berhasil Dihapus',
          },
        })
        this.refetchData()
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Sukses',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'User gagal dihapus',
          },
        })
      }
    },
    async activation(status, id) {
      if (status === 'aktif') {
        const response = await activationUser({ user_id: id, status: 'aktif' })
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sukses',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'User Berhasil diaktifkan',
            },
          })
          this.refetchData()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sukses',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'User gagal diaktifkan',
            },
          })
        }
      } else {
        const response = await activationUser({ user_id: id, status: 'non-aktif' })
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sukses',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'User Berhasil di non-aktifkan',
            },
          })
          this.refetchData()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sukses',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'User gagal di non-aktifkan',
            },
          })
        }
      }
    },
    async verifyEmail(id, emailAddress) {
      const response = await VerifyEmail({ user_id: id, email: emailAddress })
      // console.log(responseInsert)
      // if (response.data.id) {
      // this.makeToast('success', 'Email Verifikasi Dikirim!')
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Sukses',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: 'Email Verifikasi Dikirim!',
        },
      })
      // }
    },
    loginAs(id, emailAddress) {
      // alert(id)
      Authlogin({
        email: emailAddress,
        user_id: id,
        loginas: true,
      })
        .then(response => {
          if (response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Informasi',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${response.data.message}`,
              },
            })
          } else {
            // const { userData } = response.data
            const { member, urlCallback } = response.data.data
            setToken(response.data.data.accessToken)
            setRefreshToken(response.data.data.refreshToken)

            /// /////// TEMPORARY ACL SIMULATION ////////////////

            // baca value userData.roles
            // value-nya mungkin bisa  array of string atau hanya string
            // jadi perlu check if array
            let userRole = ''
            if (Array.isArray(member.roles)) {
              // ambil item pertama
              const test = member.roles[0]
              userRole = test
            } else {
              userRole = member.roles
            }
            localStorage.setItem('userData', JSON.stringify(member))
            this.$ability.update(member.ability)
            if (urlCallback === '') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Selamat Datang',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Anda telah berhasil masuk KitaKerja.id sebagai ${member.fullName}. Sekarang Anda bisa mulai menjelajah!`,
                },
              })
              window.location.href = '/dashboard/analytics'
              // this.$router.replace('/')
              //   .then(() => {
              //     this.$toast({
              //       component: ToastificationContent,
              //       position: 'top-right',
              //       props: {
              //         title: 'Selamat Datang',
              //         icon: 'CoffeeIcon',
              //         variant: 'success',
              //         text: `Anda telah berhasil masuk KitaKerja.id sebagai ${member.fullName}. Sekarang Anda bisa mulai menjelajah!`,
              //       },
              //     })
              //   })
            } else {
              localStorage.setItem('urlCallback', urlCallback)
              window.location.href = urlCallback
            }
          }
        })
    },
    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title: 'Pengguna',
        variant,
        solid: true,
        autoHideDelay: 10000,
        enableHtml: true,
        allowHtml: true,
      })
    },
    emitAddError(response) {
      this.makeToast('danger', response.data.data)
    },
    emitAddSuccess(assetId) {
      this.$router.replace(`/pengguna/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Pengguna',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    // async reloadUPTOptions(kanwilId) {
    //   this.uptFilter = null
    //   this.uptOptions = {
    //     selected: 'id',
    //     options: [
    //       { value: null, label: '--Pilih UPT --' },
    //     ],
    //   }
    //   const responseUptOptions = await getUPT(kanwilId)
    //   this.mappingSelectOption(responseUptOptions.data, this.uptOptions.options)
    // },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    mappingSelectOptionRole(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.name,
            value: element.guard_name,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna-list'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
